import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import style from "./style.module.scss";
import Button from "../../../components/atoms/Button/Button";
import { toast } from "react-toastify";
import { ISignupData } from "types/auth/auth.type";
import { signUp } from "store/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import theme from "../../../assets/themes";
import SegmentAgent from "apis/segmentAgent";
import { InputField } from "components";

const Signup: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [companyName, setCompanyName] = useState("");
  const [inviterId, setInviterId] = useState("");
  const [invitedMail, setInvitedMail] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ISignupData>();
  const { isLoading, successMessage, errorMessage, user } = useAppSelector((state) => state.auth.signUp);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleSignUp = async (data: ISignupData) => {
    try {
      dispatch(signUp({ ...data, inviterId, segmentUserId: window.analytics.user().anonymousId() }));
    } catch(error) {
      dispatch(signUp({ ...data, inviterId, segmentUserId: "blocked" }));
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) navigate("/dashboard");
  }, []);

  useEffect(() => {
    if (successMessage) {
      if (user && user.segmentUserId) {
        SegmentAgent.event({
            createdAt: new Date(),
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            company: user.organizationName,
          },
          user.segmentUserId, "", user.platformBrandId, "identify"
        )
        SegmentAgent.event({}, user.segmentUserId, "ACCOUNT_CREATED", user.platformBrandId)
      }
      toast.success(successMessage);
      // dispatch(resetSignUp());
      navigate("/");
    }
    if (errorMessage) {
      toast.error(String(errorMessage));
      // dispatch(resetSignUp());
    }
  }, [successMessage, errorMessage]);
  useEffect(() => {
    SegmentAgent.page("authSignUp", { pathName: location.pathname });
  }, []);
  useEffect(() => {
    const container = document.getElementById("bg-container");
    if (!container) return
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, [isLoading]);
  useEffect(() => {
    const inviteCompanyQuery: string = searchParams.get("companyName") || "";
    const inviterIdQuery: string = searchParams.get("inviterId") || "";
    const invitedEmailQuery: string = searchParams.get("invitedMail") || "";
    if (Boolean(inviteCompanyQuery) && Boolean(inviterIdQuery)) {
      setInviterId(inviterIdQuery);
      setCompanyName(inviteCompanyQuery);
      setInvitedMail(invitedEmailQuery);
      setValue("organizationName", companyName);
      setValue("email", invitedEmailQuery)
    }
  }, [companyName, invitedMail]);
  useEffect(() => {
    const invitedEmailQuery: string = searchParams.get("invitedMail") || "";
    if (Boolean(invitedEmailQuery)) {
      setInvitedMail(invitedEmailQuery);
      setValue("email", invitedEmailQuery)
    }
  }, [invitedMail]);
  return <div className={`${style.signup_container} sm:h-screen h-auto`} id="bg-container">
    <div className={style.logo}>
      <img className={style.img} src={theme["logo"]} alt="logo" onClick={() => navigate("/welcome")} />
    </div>
    <div className={`${style.container_wrapper} bg-paper-500`}>
      <div className={style.form_wrapper_up}>
        <form onSubmit={handleSubmit(handleSignUp)} className={style.form}>
          <h1 className="text-2xl text-center">{
            searchParams.get("companyName") && searchParams.get("inviterId") ? "Sign up to join your team" : "Create an Account"
          }</h1>
          {searchParams.get("companyName") && searchParams.get("inviterId") && (
            <p className="text-base text-gray-300 text-center mb-3">You have been invited to {companyName}. Please fill in your details below to join your team.</p>
          )}
          <div className={style.inputs}>
            <div className="flex sm:flex-row flex-col w-full sm:gap-3 gap-5">
              <InputField
                autoFocus
                type="text"
                name="firstName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "First name is required",
                  pattern: {
                    value: /^\s*[a-zA-Z]+\s*$/,
                    message: "Name must be only letters",
                  }
                }}
                placeholder="First Name"
              />
              <InputField
                type="text"
                name="lastName"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Last name is required",
                  pattern: {
                    value: /^\s*[a-zA-Z]+\s*$/,
                    message: "Last name must be only letters",
                  },
                }}
                placeholder="Last Name"
              />
            </div>
            <InputField
              type="text" name="email"
              errors={errors}
              register={register}
              disabled={Boolean(invitedMail)&&Boolean(inviterId)}
              validationSchema={{
                required: "Email is required",
                pattern: {
                  value: /^\s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+\s*$/,
                  message: "Invalid Email. Please verify and try again",
                }
              }}
              placeholder="Business Email"
            />
            <InputField
              type="text"
              name="organizationName"
              errors={errors}
              disabled={Boolean(companyName)}
              register={register}
              validationSchema={{
                required: "Company is required",
              }}
              placeholder="Company Name"
            />
            <InputField
              type="password"
              name="password"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Password is required",
                pattern: {
                  value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
                  message: "Minimum eight characters, at least one upper case English letter, one lower case English letter, one number and one special character",
                },
              }}
              placeholder="Password"
            />
          </div>
          <p className="text-sm text-center text-gray-300">
            By continuing, you agree with our{" "}
            <Link to={"/terms-of-service"} target="_blank" className="link">Terms of Service</Link>
          </p>
          <Button 
            type="secondary" action="submit" size="lg"
            className="w-full" iconName="user-plus" 
            isProcessing={isLoading} isProcessingTitle="Creating account"
          >Create account</Button>
          <div className="items-center flex flex-col">
            <p className="text-sm text-gray-300 mb-3">Already a member?</p>
            <Button size="sm" action="button" onClick={() => navigate("/signin")}>Sign In</Button>
          </div>
        </form>
      </div>
    </div>
  </div>
};

export default Signup;
