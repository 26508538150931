import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import style from "./style.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/atoms/Button/Button";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { signIn, ILoginData, resetSignIn } from "store/slices/auth.slice";
import theme from "../../../assets/themes";
import SegmentAgent from "apis/segmentAgent";
import EntryFormatter from "utils/EntryFormatter";
import { InputField } from "components";
import { useAppContext } from "utils/context/AppContext";

const Signin: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginData>();
  const { app } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  const { isLoading, isSuccess, errorMessage, user } = useAppSelector((state) => state.auth);
  //let segmentvalue:string;
  //try { segmentvalue = window.analytics.user().anonymousId() } 
  //catch(error) { segmentvalue = "blocked"; };
  const login = (data: ILoginData) => { dispatch(signIn({ ...data, appScheme: app.scheme })) }

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (isSuccess && token) {
      if (user && user.segmentUserId) {
        SegmentAgent.event(
          {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            dateLogged: new Date(),
            company: EntryFormatter.exists(user.organization)
              ? user.organization?.name
              : user.organizationName,
            companyId: EntryFormatter.exists(user.organization)
              ? user.organization?.id
              : null,
            creditBalance: user.creditBalance,
            creditPurchase: user.creditPurchase || 0,
            creditUsage: user.creditUsage || 0,
            revenue: user?.revenue || 0,
          },
          user.segmentUserId,
          "",
          user.platformBrandId,
          "identify"
        );
        SegmentAgent.event(
          { dateLogged: new Date() },
          user.segmentUserId,
          "ACCOUNT_LOGIN",
          user.platformBrandId
        );
      }
      navigate(`/dashboard`);
    }
    if (errorMessage) {
      toast.error(errorMessage, { toastId: "login-failed" });
    }
  }, [isSuccess, errorMessage]);
  useEffect(() => {
    SegmentAgent.page("authSignIn", { pathName: location.pathname });
  }, []);
  useEffect(() => {
    const container = document.getElementById("bg-container");
    if (!container) return;
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, [isLoading]);
  return (
    <div className={`${style.signup_container} h-screen`} id="bg-container">
      <div className={style.logo}>
        <img
          className={style.img}
          src={theme["logo"]}
          alt="logo"
          onClick={() => navigate("/welcome")}
        />
      </div>
      <div className={`${style.container_wrapper} bg-paper-500`}>
        <div className={style.form_wrapper_in}>
          <form onSubmit={handleSubmit(login)} className={style.form}>
            <h1 className="text-2xl text-center">
              Log In to {theme["serviceName"]}
            </h1>
            <div className={style.inputs}>
              <InputField
                name="email"
                errors={errors}
                register={register}
                validationSchema={{
                  required: "Email is required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/,
                    message: "Invalid email address",
                  },
                }}
                placeholder="Email"
              />
              <InputField
                type="password"
                name="password"
                errors={errors}
                register={register}
                validationSchema={{ required: "Password is required" }}
                placeholder="Password"
              />
            </div>
            <Button
              action="submit"
              className="w-full"
              iconName="lock"
              type="secondary"
              size="lg"
              isProcessing={isLoading}
              isProcessingTitle="Logging in"
            >
              Log in
            </Button>
            <Link
              to={"/resetPassword"}
              onClick={() => dispatch(resetSignIn())}
              className="link text-sm"
            >
              Forgot Password?
            </Link>
            <div className="items-center flex flex-col">
              <p className="text-sm text-gray-300 mb-3">
                Don't have an account?
              </p>
              <Button
                size="sm"
                action="button"
                onClick={() => navigate("/signup")}
              >
                Sign up
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
